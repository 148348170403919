
import $ from 'jquery';
import 'jquery-easing';
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css';
import '../scss/main.scss';  
import './collapse';

// jQuery to collapse the navbar on scroll

function collapseNavbar() {
    if ($(".navbar").offset().top > 200) {
        $(".navbar-fixed-top").addClass("top-nav-collapse");
        $(".navbar-holder").addClass("navbar-shift");
    }

    else {
        $(".navbar-fixed-top").removeClass("top-nav-collapse");
        $(".navbar-holder").removeClass("navbar-shift");
    }

};


$(window).scroll(collapseNavbar);
$(document).ready(collapseNavbar);




// jQuery for page scrolling feature - requires jQuery Easing plugin
$(function () {
    $('a.page-scroll').bind('click', function (event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });
});

// Closes the Responsive Menu on Menu Item Click
$('.navbar-collapse ul li a').click(function () {
    $(this).closest('.collapse').collapse('toggle');
});

// Show more button in featured description toggle
$('.showmore').click(function () {
    $(".showmore").addClass("vanish");

});

$('.showless').click(function () {
    $(".showmore").removeClass("vanish");

});


// Collapse mobile page load enable (to prevent flickering, rapid collapse/expanding)
if ($(window).width() < 768) {
    $(".collapseEnable").addClass("collapse");
}
else {
    $(".collapseEnable").removeClass("collapse");
}


$(window).resize(function () {
    if ($(window).width() < 768) {
        $(".collapseEnable").addClass("collapse");
    }
    else {
        $(".collapseEnable").removeClass("collapse");
    }
});



// Archive section hover connect arrow glyphs to mouseover

function archiveArrowStart1() {
    $("#archiveGlyph1").addClass("archiveGlyphMove");
};

function archiveArrowStart2() {
    $("#archiveGlyph2").addClass("archiveGlyphMove");
};

function archiveArrowStart3() {
    $("#archiveGlyph3").addClass("archiveGlyphMove");
};

function archiveArrowStart4() {
    $("#archiveGlyph4").addClass("archiveGlyphMove");
};

function archiveArrowFinish() {
    $(".archiveGlyph").removeClass("archiveGlyphMove");
};

$('#archive1').on('mouseover', archiveArrowStart1);
$('#archive1').on('mouseout', archiveArrowFinish);

$('#archive2').on('mouseover', archiveArrowStart2);
$('#archive2').on('mouseout', archiveArrowFinish);

$('#archive3').on('mouseover', archiveArrowStart3);
$('#archive3').on('mouseout', archiveArrowFinish);

$('#archive4').on('mouseover', archiveArrowStart4);
$('#archive4').on('mouseout', archiveArrowFinish);